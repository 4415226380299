.section{
    display:flex;
    align-items: center;
    flex-direction: column;
    gap:25px;
}

.section img{
   margin-top: 150px;
    border-radius: 50%;
    width:20%;

}

.section h1{
    text-align:center;
    width:70%;
    font-size: 60px;
    font-weight:600;
}

.section p{
    text-align:center;
    width:60%;
    font-size: 18px;
    font-weight:600;
}

.section h1 span{
    background: linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-action{
    display:flex;
    gap:35px;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    margin-bottom:50px;
}

.section-connect{
    padding:25px 40px;
    border-radius: 50px;
    background: linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
    cursor: pointer;
    transition: ease;
}

.section-connect:hover{
    border: 2px solid #FFF;
}

.section-aboutme{
    padding:25px 75px;
    border-radius:50px;
    border:2px solid #FFF;
    cursor: pointer;
    transition: ease;

}

.section-aboutme:hover{
    border-color: #c478da;
}

@media(max-width:768px){
    .section img.logo{
        margin-top: 120px;
        width: 260px;
    }
    .section h1 {
        margin-top: 20px;
        width: 90%;
        font-size: 50px;
    }
    .section p{
        width:60%;
        font-size:18px;
        line-height: 32px;
        margin: 10px 0px;
    }
    .section-action{
        gap:10px;
        font-size:15px;
        font-weight:600;
        margin-bottom: 50px;
    }
    .section-connect{
        padding:20px 30px;
        border-radius: 40px;
    }
    .section-aboutme{
        padding:20px 50px;
        border-radius: 40px;
    }
    .section img{
        margin-top:100px;
         width:40%;
     
     }
}
