.mywork{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:80px;
    margin:80px 170px;
}
.mywork-title{
    position:relative;
    padding-top:5rem;
}
.mywork-title h1{
    padding:0px 30px;
    font-size:80px;
    font-weight: 600;
}
.mywork-title img{
    position: absolute;
    bottom:0;
    right:0;
    z-index:-1;
    
    
}
.mywork-container{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows:1fr;
    gap:30px;
}

.mywork-container a{
    color: #d8d8d8;
}

.mywork-container img{
    height:280px;
    width:auto;
    box-sizing: border-box;
    transition: 0.3s;
    cursor: pointer;
}
.mywork-container img:hover{
    transform:scale(1.10);
    border:4px solid #c478da;
    border-radius:10px;
    transition:0.3s;
}

.mywork-showmore{
display:flex;
gap:15px;
border-radius:50px;
border:2px solid white;
padding:30px 60px;
font-size:22px;
font-weight: 500;
margin-bottom :80px;
transition:0.6s;
cursor:pointer;
}

.mywork-showmore a{
    color: #d8d8d8;
}

.mywork-showmore:hover{
    gap:40px;
    transition: 0.6s;
}

@media(max-width:768px){
    .mywork{
        gap:50px;
        margin:50px 70px;
        align-items: start;

    }
    .mywork-title h1{
        padding:0;
        font-size: 50px;
        font-weight: 600;
    }
    .mywork-container{
        grid-template-columns: 1fr 1fr;
        gap:60px;

    }
    .mywork-container figure img{
        width:100%;
        height:auto
    }
    .mywork-container figure a{
        max-width: 20%;
    }
    .mywork-showmore{
        padding: 20px 40px;
        font-size: 18px;
        margin:5px auto;
        cursor: pointer;
    }
}