.tools {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  margin: 80px 170px;

}

.tools-title {
  position: relative;
  padding-top:10rem;
}

.tools-title h1 {
  padding: 0px 30px;
  font-size: 80px;
  font-weight: 600;

}
.tools-section{
  display: flex;
  gap:150px;

}
.tools-left{
  display: flex;
  flex-direction: column;
  gap:30px;
}

.tools-left h1{
  font-size: 80px;
  font-weight: 700;
  background:linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tools-left p{
  max-width: 550px;
  color:#D8d8d8;
  font-size: 20px;
  line-height: 35px;

}

.tools-details{
  display: flex;
  flex-direction: column;
  gap:30px;
  color: #d8d8d8;
  font-size: 1.25rem;
}
.tools-detail{
  display:flex;
  align-items: center;
  gap: 20px;

}

.tools-right{
  display:flex;
  flex-direction: column;
  align-items: start;
  gap:30px;
}
.tools-section span{
  color: #FFFFFF;
  font-size:1rem;
  font-weight: 800;
  margin-bottom: 50px;
}

.tools-right label{
  color:#d8d8d8e8;
  font-size: 1.25rem;
  font-weight: 500;
}
.tools-right input{
  border:none;
  width:700px;
  height:78px;
  padding-left: 20px;
  border-radius: 4px;
  background: #32323c;
  color:#A0a0a0;
  font-family: Outfit;
  font-size:20px;
}

.tools-right textarea{
  width:650px;
  border: none;
  padding:25px;
  border-radius: 4px;
  background: #32323c;
  color:#A0a0a0;
  font-family: Outfit;
  font-size:20px;
}

.tools-submit{
  border:none;
  color:white;
  border-radius: 50px;
  background: linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
  font-size: 1.25rem;
  padding:20px 60px;
  cursor: pointer;
  transition:0.3s;
}


.tools-submit:hover{
  transform: scale(1.1);
  transition: 0.3s;
}

@media(max-width:768px) {
  .tools{
      gap:50px;
      margin:50px 70px;
      align-items: start;
  }
  .tools-title h1{
      padding:0;
      font-size: 50px;
      font-weight:600
  }
  .tools-section{
      flex-direction: column;
  }
  .tools-left h1{
      font-size: 54px;
  }
  .tools-left p{
      font-size: 18px;
  }
  .tools-detail{
      gap:20px;
  }
  .tools-detail img{
      width:25px;
  }
  .tools-right label{
      font-size: 20px;
  }
  .tools-right input{
      width:100%;
      font-size:20px
  }
  .tools-right textarea{
      width:95%;
      font-size:20px
  }
  .tools-submit{
      font-size: 20px;
      padding:15px 40px;
  }
}