.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin: 80px 170px;

}

.contact-title {
    position: relative;
    padding-top:10rem;
}

.contact-title h1 {
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;

}
.contact-section{
    display: flex;
    gap:150px;

}
.contact-left{
    display: flex;
    flex-direction: column;
    gap:30px;
}

.contact-left h1{
    font-size: 80px;
    font-weight: 700;
    background:linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-left p{
    max-width: 550px;
    color:#D8d8d8;
    font-size: 20px;
    line-height: 35px;

}

.contact-details{
    display: flex;
    flex-direction: column;
    gap:30px;
    color: #d8d8d8;
    font-size: 1.25rem;
}
.contact-detail{
    display:flex;
    align-items: center;
    gap: 20px;

}

.contact-right{
    display:flex;
    flex-direction: column;
    align-items: start;
    gap:30px;
}
.contact-section span{
    color: #FFFFFF;
    font-size:1rem;
    font-weight: 800;
    margin-bottom: 50px;
}

.contact-right label{
    color:#d8d8d8e8;
    font-size: 1.25rem;
    font-weight: 500;
}
.contact-right input{
    border:none;
    width:700px;
    height:78px;
    padding-left: 20px;
    border-radius: 4px;
    background: #32323c;
    color:#A0a0a0;
    font-family: Outfit;
    font-size:20px;
}

.contact-right textarea{
    width:650px;
    border: none;
    padding:25px;
    border-radius: 4px;
    background: #32323c;
    color:#A0a0a0;
    font-family: Outfit;
    font-size:20px;
}

.contact-submit{
    border:none;
    color:white;
    border-radius: 50px;
    background: linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
    font-size: 1.25rem;
    padding:20px 60px;
    cursor: pointer;
    transition:0.3s;
}


.contact-submit:hover{
    transform: scale(1.1);
    transition: 0.3s;
}

@media(max-width:768px) {
    .contact{
        gap:50px;
        margin:50px 70px;
        align-items: start;
    }
    .contact-title h1{
        padding:0;
        font-size: 50px;
        font-weight:600
    }
    .contact-section{
        flex-direction: column;
    }
    .contact-left h1{
        font-size: 54px;
    }
    .contact-left p{
        font-size: 18px;
    }
    .contact-detail{
        gap:20px;
    }
    .contact-detail img{
        width:25px;
    }
    .contact-right label{
        font-size: 20px;
    }
    .contact-right input{
        width:100%;
        font-size:20px
    }
    .contact-right textarea{
        width:95%;
        font-size:20px
    }
    .contact-submit{
        font-size: 20px;
        padding:15px 40px;
    }
}