.navbar{
    position: fixed;;
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding:0px 170px;
    background-color: #161513;
    z-index: 1100;
    margin: 0px 50px;
}

.navbar .underline{
    text-decoration: underline #c478da;
}

.navbar img.logo{
    width:15%
}
.nav-menu{
    display:flex;
    align-items: center;
    list-style: none;
    gap:50px;
    font-size: 20px;
    padding: 50px;
}

.nav-menu li{
    cursor: pointer;
}

.nav-connect{
    font-size: 20px;
    padding: 20px 40px;
    border-radius: 50px;
    background: linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
    color: black;
    cursor: pointer;  
    transition: 0.5s; 
}
.nav-connect:hover{
    transform: scale(1.05);
}

.nav-connect a{
    text-decoration: none;
}

.anchor-link{
    color: white;
    text-decoration: none;
}

.nav-mob-close,.nav-mob-open{
    display: none;
}



@media(max-width:768px) {
    .navbar{
        padding:20px 0px;
        width: 100%;
        margin:0px 20px;
    }
    .nav-connect{
        display:none;
    }
    .nav-mob-open{
        display: block;
        position:fixed;
        right:30px;
    }
    .nav-mob-close{
        display: block;
        position:relative;
        top:30px;
        left:290px;
        width:30px;
    }

    .nav-menu{
        position:fixed;
        flex-direction: column;
        align-items: start;
        top:0;
        gap:30px;
        background-color: #1f0016 ;
        width: 400px;
        height: 100%;
        z-index: 2;
        transition: right 0.5s;
        right: -400px
    }
    .nav-menu li{
        font-size: 30px;
        padding-left: 100px;
        flex-direction: row;
        gap:20px;
        
    }
}