.about{
display:flex;
justify-content: center;
flex-direction: column;
align-items: center;
gap:80px;
margin:80px 170px;


}

.about-title{
    position:relative;
    padding-top:5rem;
}
.about-title h1{
padding:0px 30px;
font-size:80px;
font-weight: 600;
}

.about-sections{
    display:flex;
    gap:80px;
}

.about-right{
    display:flex;
    gap:3.125rem;
    flex-direction: column;
}

.about-para{
    display:flex;
    gap:20px;
    flex-direction: column;
    font-size:18px;
    font-weight: 500;
}
.about-skills{
    display:flex;
    flex-direction: column;
    gap:20px;
}

.about-skill{
    display:flex;
    transition: 0.3s;
    gap:50px;
    align-items: center;
}
.about-skill p{
    min-width:180px;
    font-size:18px;
    font-weight:500;
}
.about-skill hr{
    outline:none;
    border:none;
    width:50%;
    height: 8px;
    border-radius: 50px;
    background: linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
}
.about-achievements{
    display:flex;
    width:100%;
    justify-content:space-around;
    margin-bottom: 80px;
}
.about-achievement{
    display:flex;
    flex-direction:column;
    align-items: center;
    gap:10px;
    transition:0.5s;
}
.about-achievement h1{
    font-size:60px;
    font-weight:700;
    background:linear-gradient(270deg,#FFDDF4 .86%,#c478da 102.06%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about-achievement p{
    font-size:22px;
    font-weight: 500;
}
.about-skill:hover{
    transform: scale(1.05);
    transition:0.3s;

}

.about-achievement:hover{
    transform: scale(1.12);
    transition:0.5s;

}

@media(max-width:768px){
    .about{
        gap:60px;
        margin:50px 70px;
        align-items: start;
    }
    .about-title h1{
        font-size: 50px;
        padding:0px;

    }
    .about-sections{
        flex-direction: column;
    }
    .about-left{
        display: none;
    }
    .about-para{
        font-size: 18px;
        line-height: 32px;

    }
    .about-skill{
        /* gap:20px;
        align-items: center; */
        gap: 2px;
        align-items: flex-start;
        flex-direction: column;

    }
    .about-skill p{
        font-size: 20px;
    }
    .about-skill hr{
        height:6px;
    }
    .about-achievements{
        justify-content: space-between;
    }
    .about-achievement{
        gap:10px;
        padding:10px;
    }
    .about-achievement h1{
        font-size:34px;
        font-weight:700
    }
    .about-achievement p {
        text-align: center;
        font-size: 16px;
    }
}