.footer {
    display: flex;
    margin: 50px 170px;
    flex-direction: column;
    gap: 1.25rem;

}

.footer-top {
    display: flex;
    justify-content: space-between;
}

.footer-top-left p {
    font-size: 20px;
    max-width: 400px;

}

.footer-top-left img{
    width:40%;
}

.footer-top-right {
    display: flex;
    align-items: center;
    gap: 15px;
}

.footer-email-input {
    display: flex;
    gap: 30px;
    padding: 20px 30px;
    border-radius: 50px;
    background: #32323B;
    padding-right: 80px;
}

.footer-email-input:hover{
    border:2px solid white;
}

.footer-email-input input {
    outline: none;
    border: none;
    background: transparent;
    color: #a0a0a0;
    font-family: Outfit;
    font-size: 18px;
}

.footer-subscribe {
    font-size: 20px;
    padding: 22px 50px;
    border-radius: 50px;
    background: linear-gradient(270deg, #FFDDF4 .86%, #c478da 102.06%);
    cursor: pointer;
    transition: 0.3s;

}

.footer-subscribe:hover{
transform: scale(1.1);
transition: 0.3s;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    font-size: 18px;

}

.footer-bottom p a{
color:white;
}

.footer-bottom-right {
    display: flex;
    gap: 50px;
    margin-bottom: 50px;

}

@media(max-width:768px){
    .footer{
        margin: 10px 50px;
        gap:0.2rem;
    }

    .footer-top-left img{
        width:50%;
    }
    .footer-top-right p{
        font-size:12px;
        text-align: right;
    }

    .footer-bottom p{
        font-size: 12px;;
    }

}