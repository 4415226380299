.privacy{
    padding:50px;
}
.privacy a, em,span{
    color: #FFDDF4;
}
.privacy a.home{
    color: yellow;
    cursor: pointer;
    font-weight:500;
}